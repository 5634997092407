import './styles.css';

const Loader = ({isLoading = false}) => {

        if (!isLoading){
            return
        }
        return (
            <div className='loader-container'>
                <span className="loader"></span>
            </div>
        )
}

export default Loader;