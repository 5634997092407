import aquarius from '../assets/zodiacs/aquarius.svg';
import pisces from '../assets/zodiacs/pisces.png';
import aries from '../assets/zodiacs/aries.svg';
import taurus from '../assets/zodiacs/taurus.svg';
import gemini from '../assets/zodiacs/gemini.svg';
import canser from '../assets/zodiacs/canser.png';
import leo from '../assets/zodiacs/leo.svg';
import virgo from '../assets/zodiacs/virgo.svg';
import libra from '../assets/zodiacs/libra.svg';
import scorpio from '../assets/zodiacs/scorpio.svg';
import sagittarius from '../assets/zodiacs/sagittarius.svg';
import capricorn from '../assets/zodiacs/capricorn.svg';

import air from '../assets/elements/air.svg';
import water from '../assets/elements/water.svg';
import fire from '../assets/elements/fire.svg';
import earth from '../assets/elements/nature.svg';

const zodiacsMainInfo = {
    aquarius: {
        label: 'Водолей',
        imgSrc: aquarius,
        date: { start: '20 января', end: '19 февраля' },
        description:
            'Интересный персонаж! Настоящий краш, легкий, обаятельный и элегантный. Умеет произвести на окружающих обманчивое впечатление: якобы он легкомысленный простачок и ни о чем не умеет переживать дольше десяти минут. На самом деле Водолей — знак зодиака, чей подопечный обычно бывает очень целеустремленным, амбициозным, принципиальным человеком. Его до глубины души ранит любая несправедливость. Он может бросить дело на полдороге, если интуиция скажет ему «стой», но друзей Водолеи никогда не предадут.',
        patron: 'Сатурн и Уран',
        descriptionPatron:
            'Эти два гиганта будто тянут мнительного Водолея в разные стороны, поэтому он бывает таким импульсивным, спонтанным и непредсказуемым. Но это не взбалмошность, а просто поиск баланса между хаосом и порядком.',
        element: 'Воздух',
        elementSrc: air,
        elementDescription:
            'Этого обаятельного героя (или героиню) как будто южный ветер сам несет в сторону правильного решения. Трудно угадать, что он скажет в следующий момент, где проснется завтра утром. Его путь — либо гениальность, либо безумие, либо то и другое вместе.',
    },
    pisces: {
        label: 'Рыбы',
        imgSrc: pisces,
        date: { start: '20 февраля', end: '20 марта' },
        description:
            'Эта ранимая натура — про мир, а не войну. Рыбы чувствительны, нежны, милы, с отличными инстинктами заботы. Бывают легкомысленны, мнительны, недоверчивы, стараются принимать слабые, но надежные решения. Из Рыб получаются верные друзья и ласковые родители.',
        patron: 'Нептун',
        descriptionPatron:
            'Патрон загадочных Рыб — Нептун. Прохладный, сдержанный, непредсказуемый, управляющий интуицией и потаенными силами этого мира.',
        element: 'Вода',
        elementSrc: water,
        elementDescription:
            'Рыбы — представитель той воды, которая ловко лавирует между преградами, постепенно прокладывая новое русло. Капля камень точит — это про Рыб: не нужно резких движений, внезапных свершений, пусть лучше все течет себе потихоньку.',
    },
    aries: {
        label: 'Овен',
        imgSrc: aries,
        date: { start: '21 марта', end: '19 апреля' },
        description:
            'Сильный, агрессивный, темпераментный. И мужчина, и женщина, рожденные Овнами, заряжают всех вокруг своим энтузиазмом, здоровым азартом и упрямством. Эти люди импульсивны, сексуальны, часто поддаются внутренним порывам. Камень за пазухой — не про них: сразу говорят в глаза все, что думают. К сожалению, Овен — знак зодиака не про командную игру и солидарность. Зато и в одиночку этот упрямый энтузиаст может достичь небывалых высот.',
        patron: 'Марс',
        descriptionPatron:
            'Красная планета войны, силы и страсти, поэтому любимые вещи всех Овнов часто связаны с огнем.',
        element: 'Огонь',
        elementSrc: fire,
        elementDescription:
            'Овнам невыносимы тишина, покой, ясность и порядок. Они умеют управлять энергией хаоса и не нуждаются ни в каком внешнем контроле. Главное — не дайте им остаться в тени: только аплодисменты и восхищенные взгляды помогут небесному барашку поддерживать внутреннее пламя и упрямо идти к новым свершениям.',
    },
    taurus: {
        label: 'Телец',
        imgSrc: taurus,
        date: { start: '20 апреля', end: '20 мая' },
        description:
            'Добродушный миротворец, скромный семьянин, послушный теленок, который уважает правила и договоренности, ценит в людях честь и верность, а если рядом кто-то ведет себя подло — пустит в ход копыта и не пожалеет. Его идеализм и требовательность к себе могут вызвать как восхищение, так и скуку и досаду. Под этим созвездием рождаются великие политики, фанатики, ученые и самоотверженные герои.',
        patron: 'Венера',
        descriptionPatron:
            'Тельца оберегает нежная голубая Венера. Она озаряет спокойным светом своих подопечных, придает их характеру мягкость, чувствительность, чуть стачивает их упрямые рога. Телец — знак зодиака, который острее всех нуждается в любви и понимании, а взамен готов нежно заботиться о партнере.',
        element: 'Земля',
        elementSrc: earth,
        elementDescription:
            'Упрямство Тельца — это мягкая сила: он твердо стоит на своем, буквально врастает в землю и не слышит ничьих аргументов. Зато благодаря земной стихии он прекрасно зарабатывает, обеспечивает всех родных и близких, буквально притягивает к себе достаток и все свое хозяйство содержит в полном порядке.',
    },
    gemini: {
        label: 'Близнецы',
        imgSrc: gemini,
        date: { start: '21 мая', end: '20 июня' },
        description:
            'Май и июнь — время динамичных, игривых Близнецов: умных, ловких, предприимчивых, ярких, легких на подъем, с искрометным чувством юмора. Главная слабость Близняшек — легкомыслие и небрежность в отношениях: иногда они могут не рассчитать сил и ради красного словца ранить даже самых близких. Если вы ищете красноречивого, убежденного, веселого и подвижного лида для своей команды, Близнецы — знак зодиака, который вам поможет.',
        patron: 'Меркурий',
        descriptionPatron:
            'Шустрый, подвижный Меркурий — вестник богов и вечный трикстер. Ему подвластны слова, речи, деньги и авантюры. Эта характеристика передается и тем, кто родился под созвездием Близнецов.',
        element: 'Воздух',
        elementSrc: air,
        elementDescription:
            'Близнецы вечно куда-то мчат — обычно без всякой цели, просто туда, куда ноги несут. Любимцев Меркурия не могут вдохновить простые и понятные вещи, домашний уют и спокойное времяпрепровождение: только спонтанность и хардкор.',
    },
    canser: {
        label: 'Рак',
        imgSrc: canser,
        date: { start: '21 июня', end: '22 июля' },
        description:
            'Если вы ищете самого сложного, опасного и в то же время чувствительного героя гороскопов, то это точно Рак. Знак зодиака, под которым рождаются люди настолько же сентиментальные и эмпатичные, насколько жестокие и беспощадные (если им вдруг покажется, что вы обидчик). Обычно это уточненный и ранимый человек, который и рад бы забыть прошлое, но не может. Стоит только нарушить доверие — и он замкнется в своей раковине, оставив вам только любезную холодную улыбку.',
        patron: 'Луна',
        descriptionPatron:
            'Раки живут под сильным влиянием Луны. Как и она, эти чувствительные натуры импульсивны, порывисты, готовы чуть что разразиться слезами или запылать энтузиазмом к новому делу.',
        element: 'Вода',
        elementSrc: water,
        elementDescription:
            'Обычно Рак обладает подвижным, «текучим» характером. Мысли его бегут быстро, как ручеек среди валунов, новые планы и свершения текут полноводной рекой.',
    },
    leo: {
        label: 'Лев',
        imgSrc: leo,
        date: { start: '23 июля', end: '22 августа' },
        description:
            'Харизматичный, притягательный, роскошный, Лев всегда в центре внимания, к его словам прислушиваются, ему повинуются. Кроме того, Львы великодушны, дружелюбны, обожают покровительствовать тем, кто рядом, и поддерживать их. Иногда бывают наивными, как дети, но в этом и плюс: Льва можно лаской и терпением убедить в чем угодно.',
        patron: 'Солнце',
        descriptionPatron:
            'От этого светила Лев получает свое царственное сияние, благородство и блеск. Правда, из-за влияния Солнца он же бывает горяч, импульсивен, нетерпелив и вздорен. Если начнет полыхать — лучше держитесь подальше',
        element: 'Огонь',
        elementSrc: fire,
        elementDescription:
            'Море энергии и решимости, сексуальность и грация — все это превращает Львов и Львиц в настоящих кумиров.',
    },
    virgo: {
        label: 'Дева',
        imgSrc: virgo,
        date: { start: '23 августа', end: '22 сентября' },
        description:
            'Это идеальный человек: дисциплинированный, умный, неутомимый, работоспособный, с высоким эмоциональным интеллектом, а также верный и честный. Полный порядок в голове и в сердце — фирменный стиль Девы. Главное — не стать занудой и не требовать от окружающих того же, что и от себя.',
        patron: 'Меркурий, Солнце, Венера',
        descriptionPatron:
            'Этих счастливцев судьба не обделила! Им дарованы и тепло Солнца, и сияние Венеры, и легкость Меркурия. Отсюда и одаренность, и трудолюбие, и умение поддерживать баланс между рассудком и эмоциями.',
        element: 'Земля',
        elementSrc: earth,
        elementDescription:
            'Дева не из тех, кто ноет по пустякам или поддается унынию.',
    },
    libra: {
        label: 'Весы',
        imgSrc: libra,
        date: { start: '23 сенятября ', end: '23 октября' },
        description:
            'Помирить влюбленных, сделать врагов союзниками, познакомить партнеров — все это Весы умеют, как никто другой. Компанейские, дружелюбные, целеустремленные, живые, полные обаяния. Их аристократичность и превосходные манеры скрашивают все недостатки характера.',
        patron: 'Венера',
        descriptionPatron:
            'У всех двенадцати героев есть свои особенности. Например, Весы — знак зодиака, который представлен единственным неодушевленным предметом во всем зодиакальном круге. В его представителях очень много рассудка, порядка, а все благодаря спокойной Венере. Она позволяет Весам не быть слишком упрямыми и добиваться желаемого более изящными методами, чем горячий натиск.',
        element: 'Воздух',
        elementSrc: air,
        elementDescription:
            'Это не тот ураган, который свойствен Водолеям и Близнецам. Весы также игривы, подвижны, обаятельны, очень любят компанию, при этом чутко слышат свои внутренние потребности.',
    },
    scorpio: {
        label: 'Скорпион',
        imgSrc: scorpio,
        date: { start: '24 октября', end: '22 ноября' },
        description:
            'Практичные, остроумные, целеустремленные, скрытные, представители этого символа полны магнетизма и обаяния. Они не имеют свойства посвящать людей в свои дела, многое держат в секрете, любят проявить власть и повернуть ход истории под себя.',
        patron: 'Плутон',
        descriptionPatron:
            'Загадочное, таинственное небесное светило, противоречивое и имеющее связь с миром магии. Скорпионы, как никто другой, умеют разрушать, чтобы создавать вновь, прощаться со старым и давать жизнь неожиданным делам и проектам.',
        element: 'Вода',
        elementSrc: water,
        elementDescription:
            'Вот уж о ком говорят — «темный омут»! Страстный Скорпион сам не всегда понимает, что ему нужно. Великие решения он принимает в считаные секунды, а по мелочам долго сомневается.',
    },
    sagittarius: {
        label: 'Стрелец',
        imgSrc: sagittarius,
        date: { start: '23 ноября', end: '21 декабря' },
        description:
            'Импульсивные натуры, рожденные под влиянием Юпитера, — известные адреналиновые маньяки. Они заряжаются от трудностей, обожают ставить недостижимые цели и каким-то чудом достигать их. Страстные, целеустремленные, прилежные, Стрельцы живут каждый день, как последний, и полностью готовы отдать себя любимому человеку или делу.',
        patron: 'Юпитер',
        descriptionPatron:
            'Это самый сильный небесный патрон из всех возможных. Благодаря ему мужчина-Стрелец смело идет к своей цели, остается мужественным, в любой ситуации умеет трезво мыслить, а женщина во всем стремится к совершенству, неутомима, заботлива и вся пылает страстью к любимому.',
        element: 'Огонь',
        elementSrc: fire,
        elementDescription:
            'Этот огненный герой умеет направлять свои стрелы точно в цель и не опаляет своей страстью все вокруг. Стрельцы чувственны, изобретательны, способны согреть своим душевным теплом друзей и близких.',
    },
    capricorn: {
        label: 'Козерог',
        imgSrc: capricorn,
        date: { start: '21 декабря', end: '19 января' },
        description:
            'Самый трезвый и здравомыслящий во всем гороскопе: его представитель умеет распределять силы, проявлять терпение, прощать, планировать и просто быть рядом в трудную минуту. Его единственный минус — болезненная самокритичность и неумение отдыхать. Козерог — неистовый трудоголик!',
        patron: 'Сатурн',
        descriptionPatron:
            'За Козерогами присматривает мощный и влиятельный Сатурн. Он не всегда может одарить своих подопечных эффектным блеском, но трезвости и работоспособности дает им с лихвой. ',
        element: 'Земля',
        elementSrc: earth,
        elementDescription:
            'Козерог весь излучает уверенность, даже когда делает что-то не то. Он отлично заземлен, расчетлив, пунктуален, умеет зарабатывать деньги и из каждой мелочи извлекать пользу.',
    },
};

export default zodiacsMainInfo;
