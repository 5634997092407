import { useEffect } from 'react';
import './styles.css';

const AdBanner = ({ bannerId }) => {
    const windowWidth = window.innerWidth
    const isDesktop = windowWidth > 950;
    
    const width = isDesktop ? '300px' : `${windowWidth - 100}px`;
    const height = isDesktop ? '600px' : '300px';
    
    useEffect( () => {
        try {
            const script = document.createElement("script");
            script.src = "https://cdn1.moe.video/p/b.js";
            script.onload = () => {
                // eslint-disable-next-line
                addBanner({
                    element: `#banner-${bannerId}`,
                    placement: 11515,
                    width: width,
                    height: height,
                    advertCount: 0,
                    deviceMode: 'all',
                    adLoad: () => { console.info("Banner Ad Loaded") },
                    adStart: () => { console.info("Banner Ad Started") },
                    adEmpty: () => { console.info("Banner Ad Empty") },
                    adImp: () => { console.info("Banner Ad Impression") },
                    adFinish: () => { console.info("Banner Ad Finised") },
                    adError: () => { console.info("Banner Ad Error") },
                })
            }
            document.body.append(script);
        } catch (error) {
            console.log("Banner error");
        }
    }, [])

    return (
        <div id={`banner-${bannerId}`} className="banner-box"></div>
    )
}

export default AdBanner;