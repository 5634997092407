import "./styles.css";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="footer">
            {year} © Все права защищены
        </footer>
    )

}

export default Footer;