import { useEffect } from 'react';
import './styles.css';

const AdPalyer = () => {
    useEffect(()=> {
        try {
        const script = document.createElement("script");
        script.src = "https://cdn1.moe.video/p/cr.js";
        script.onload = () => {
            // eslint-disable-next-line
            addContentRoll({
                element: '#contentroll',
                width: '100%',
                placement: 11515,
                promo: true,
                advertCount: 0,
                slot: 'page',
                sound: 'onclick',
                deviceMode: 'all',
                fly:{
                    mode: 'always',
                    animation: 'fly',
                    width: 445,
                    closeSecOffset: 5,
                    position: 'bottom-right',
                    indent:{
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    },
                    positionMobile: 'bottom',
                },
                playerLoad: () => { console.info("Player Loaded") },
                playerClose: () => { console.info("Player Closed") },
                adLoad: () => { console.info("Player Ad Loaded") },
                adStart: () => { console.info("Player Ad Started") },
                adImp: () => { console.info("Player Ad Impression") },
                ad25: () => { console.info("Player Ad 25") },
                ad50: () => { console.info("Player Ad 50") },
                ad75: () => { console.info("Player Ad 75") },
                adCompl: () => { console.info("Player Ad Completed") },
                adFinish: () => { console.info("Player Ad Finished") },
                adError: () => { console.info("Player Ad Error") },
                adEmpty: () => { console.info("Player Ad Empty") },
            });
        };
        document.body.append(script);
        } catch (error) {
            console.log("Ad player error");
        }}, []
    )

    return (
        <div id="contentroll"></div>
    )
}

export default AdPalyer;