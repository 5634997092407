import { Outlet } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AdBanner from '../../components/AdBanner';
import AdPalyer from '../../components/AdPalyer';
import "./MainLayout.css";

const MainLayout = () => {

    return (
        <>
            <Header />
            <div className='main-content'>
                <AdBanner bannerId="left"/>
                <AdPalyer />
                <Outlet />
                <AdBanner bannerId="right"/>
            </div>
            <Footer />
        </>
    );
};

export default MainLayout;
