import './App.css';
import { Suspense } from 'react';
import MainLayout from './pages/Layouts/MainLayout';
import Loader from './components/Loader';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { lazy } from 'react';

const ZodiacPage = lazy(() => import('./pages/ZodiacPage'));
const MainPage = lazy(() => import('./pages/MainPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));

function App() {
    return (
        <Suspense fallback={<Loader isLoading={true} />}>
            <Router>
                <Routes>
                    <Route path='/error' element={<NotFoundPage />} />
                    <Route
                        element={<MainLayout />}
                        errorElement={<NotFoundPage />}
                    >
                        <Route path='/' element={<MainPage />} />
                        <Route path='/:zodiac?' element={<ZodiacPage />} />
                    </Route>
                </Routes>
            </Router>
        </Suspense>
    );
}

export default App;
